import React from 'react'
import styles from './style.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "life_image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 697, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="op_block space_block">
            <div className={styles.life}>
                <div className={styles.life_box}>
                    <div className={styles.life_box_title}>Ecosystem and Big Data</div>
                    <div className={styles.life_box_description}>
                    our products create a user ecosystem in order to make it a habit to use user needs with our products. and we analyze big data for product development purposes not for misuse
                    </div>
                </div>
                <Img fluid={data.file.childImageSharp.fluid} className={styles.life_bg} />
            </div>
        </div>
    )
}
import React, { Fragment } from 'react'
import styles from './style.module.scss'
import Android from '@/images/android.svg'
import Ios from '@/images/apple.svg'
import Desktop from '@/images/desktop.svg'

export default ({type, active, onclick}) => {

    const ViewAndroid = (
        <Fragment>
            <div className={styles.item_content}>
                <div className={active ? styles.item_content_android_bg_selected : styles.item_content_android_bg_unselected}></div>
                <Android className={active ? styles.item_content_android_icon_selected : styles.item_content_android_icon_unselected} />
            </div>
            <div className={active ? styles.item_content_android_title_selected : styles.item_content_android_title_unselected}>Android</div>
            <div className={active ? styles.item_content_android_divider_selected : styles.item_content_android_divider_unselected}></div>
        </Fragment>
    )

    const ViewIos = (
        <Fragment>
            <div className={styles.item_content}>
                <div className={active ? styles.item_content_ios_bg_selected : styles.item_content_ios_bg_unselected}></div>
                <Ios className={active ? styles.item_content_ios_icon_selected : styles.item_content_ios_icon_unselected} />
            </div>
            <div className={active ? styles.item_content_ios_title_selected : styles.item_content_ios_title_unselected}>IOS</div>
            <div className={active ? styles.item_content_ios_divider_selected : styles.item_content_ios_divider_unselected}></div>
        </Fragment>
    )

    const ViewDesktop = (
        <Fragment>
            <div className={styles.item_content}>
                <div className={active ? styles.item_content_desktop_bg_selected : styles.item_content_desktop_bg_unselected}></div>
                <Desktop className={active ? styles.item_content_desktop_icon_selected : styles.item_content_desktop_icon_unselected} />
            </div>
            <div className={active ? styles.item_content_desktop_title_selected : styles.item_content_desktop_title_unselected}>Website</div>
            <div className={active ? styles.item_content_desktop_divider_selected : styles.item_content_desktop_divider_unselected}></div>
        </Fragment>
    )

    return (
        <div onClick={() => onclick()} className={styles.item}>
            {type === 'android' ? ViewAndroid : type === 'ios' ? ViewIos : ViewDesktop}
        </div>
    )
}
import React from "react"
import Layout from '../components/Layout'
import Promo from '../components/Promo'
import Product from '../components/Product'
import Market from '../components/Market'
import Lifecycle from '../components/Lifecycle'
import Contact from '../components/Contact'

const IndexPage = () => (
  <Layout>
    <Promo />
    <Product />
    <Market />
    <Lifecycle />
    <Contact />
  </Layout>
)

export default IndexPage

import React from 'react'
import styles from './style.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "market_image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 668, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="op_block space_block">
            <div className={styles.market}>
                <div className={styles.market_box}>
                    <div className={styles.market_box_title}>How We Found The Market</div>
                    <div className={styles.market_box_description}>
                    Our target market is of course digital users, with the strengths of our unique, sophisticated and trustworthy product market users. Our products are also integrated with market analysis and user experience which will automatically generate reports to advance our target market
                    </div>
                </div>
                <Img fluid={data.file.childImageSharp.fluid} className={styles.market_bg} />
            </div>
        </div>
    )
}
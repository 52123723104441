import React, { useState } from 'react'
import Item from './Item'
import styles from './style.module.scss'

export default function() {

    const [status, setStatus] = useState('android')

    const BoxAndroid = () => (
        <div className={styles.box_android}>
            <div className={styles.box_android_title}>
                Android Apps
            </div>
            <div className={styles.box_android_description}>
                We build apps with device of biggest target market in the world. An Android reaches all people around the world with full support and ease of use
            </div>
        </div>
    )

    const BoxIos = () => (
        <div className={styles.box_ios}>
            <div className={styles.box_ios_title}>
                IOS Apps
            </div>
            <div className={styles.box_ios_description}>
                Premium, special, exclusive, elegant, smooth, beauty, and specific target markets are the main goals of making apps for iOS
            </div>
        </div>
    )

    const BoxDesktop = () => (
        <div className={styles.box_desktop}>
            <div className={styles.box_desktop_title}>
                Website
            </div>
            <div className={styles.box_desktop_description}>
                The last target market is the website. of course with millions of people every day opening it, by presenting a stunning display, fast loading, and dynamic data access will make many users interested
            </div>
        </div>
    )

    return (
        <div>
            <div className={styles.list}>
                <div className={styles.list_content}>
                    <Item onclick={() => setStatus('android')} type="android" active={status === 'android'} />
                    <Item onclick={() => setStatus('ios')} type="ios" active={status === 'ios'} />
                    <Item onclick={() => setStatus('desktop')} type="desktop" active={status === 'desktop'} />
                </div>
            </div>
            {
                status === 'android' ? <BoxAndroid /> : status === 'ios' ? <BoxIos /> : <BoxDesktop />
            }
        </div>
    )
}
import React from 'react'
import styles from './style.module.scss'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';

export default () => {

    const data = useStaticQuery (graphql`
        query {
            file(relativePath: { eq: "promo_image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1689, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.promo}>
            <div className="op_block">
                <div className={styles.promo_title}>We Create The Future Technology Apps</div>
                <div className={styles.promo_subtitle}>finding solution and ecosystem of technology</div>
            </div>
            <div className="space_block">
                <GatsbyImage fluid={data.file.childImageSharp.fluid} />
            </div>
        </div>
    )
}
import React from 'react'
import styles from './style.module.scss'

export default () => {

    return (
        <div className="op_block space_block">
            <div className={styles.contact}>
                <div className={styles.contact_title}>
                    Contacts
                </div>
                <div className={styles.contact_description}>
                    Fill free to contact us if you need more information
                </div>
                <div className={styles.contact_btn}>
                    <a href="mailto:info@epicrondigital.com">Contact Us</a>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import styles from './style.module.scss'
import Lists from './Lists'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "product_image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 906, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="space_block">
            <div className={styles.product}>
                <div className="op_block">
                    <div className={styles.product_center}>
                        <div className={styles.product_title}>Our Products</div>
                        <div className={styles.product_content}>
                            <Lists />
                            <Img fluid={data.file.childImageSharp.fluid} className={styles.product_content_bg} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}